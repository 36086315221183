//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import _ from 'lodash'
import { userAPI } from "../api"
import { CONNEXION_THROTTLE_DELAY } from "../constants"

export default Vue.extend({
  middleware: ['notAuthenticated'],
  methods: {
    submitLogin: _.throttle(function () {
      this.connexionLoading = true
      const _self = this
      userAPI.authenticate(this.login, this.password).then(response => {
        this.connexionLoading = false
        if(_.isString(response.data.token)) {
          _self.token = response.data.token
          _self.retrieveUser(true)
        } else {
          this.popSnackBar('Une erreur est survenue, damn !', "error")
          this.$refs.loginform.resetValidation()
          this.$refs.loginform.reset()
        }
      }).catch((err) => {
        this.connexionLoading = false
        if(err.response && (err.response.status === 401 || err.response.status === 422 || err.response.status === 404)) {
          console.log('err')
          this.loginError = true
          this.password = ''
          this.popSnackBar('Aucun compte ne porte ces identifiants !', "error")
        } else {
          console.log('An error occured when login: ' + err)
        }
      })
    }, CONNEXION_THROTTLE_DELAY, { leading: true, trailing: true }),
    async retrieveUser() {
      try {
        const response = await userAPI.me(this.token);
        if(_.isObject(response.data.me)) {
          this.$store.dispatch('main/login', response.data.me).then(() => {
            if(!this.hasAPCAccess) {
              this.popSnackBar('Vous n\'êtes pas autorisé !', "error")
              this.$store.dispatch('main/logout')
              return false
            }
            this.$router.push({ name: 'dashboard' })
            this.setCookie('token', this.token)
            this.popSnackBar('C\'est un plaisir de vous voir'+ (this.isAdmin ? ' Archimage ':'Mage') + this.user.username+' !', "success");
            this.login = ''
            this.password = ''
          });
        } else {
          this.popSnackBar('Une erreur est survenue lors de la récupération des données user !', "error")
        }
      } catch(error) {
        this.rejectToken(error);
      }
    }
  },
  data: () => ({
    connexionLoading: false,
    login: '',
    password: '',
    showp: false,
    loginError: false,
    loginRules: [
      v => !!v || 'l\'email est requit',
      v => /.+@.+\..+/.test(v) || 'l\'email doit être valide !',
    ],
    passwordRules: [
      v => !!v || 'le mot de passe est requit',
      v => /(.+){8,}/.test(v) || 'le mot de passe est trop court !',
    ],
    items: [
      ["fa-kiwi-bird", "Anime / Episode"],
      ["fa-newspaper", "News"],
      ["fa-film", "Slider"],
      ["fa-users", "Users"],
      ["fa-exclamation", "Reports"],
    ]
  }),
});
